<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-card class="card-shadow border-radius-xl">
        <div class="ma-5">
          <!-- Style Fix -->
          <v-row>
            <v-col></v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <label class="text-md font-weight-bolder ms-1 mandatory"
                >GST Type</label
              >
              <v-select
                v-model="gstType"
                @change="typeChange"
                :items="gstTypes"
                item-text="type"
                item-value="type"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  input-icon
                  mt-2
                "
                dense
                flat
                filled
                solo
                height="43"
                persistent-hint
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="4" v-show="showLgst">
              <label class="text-md font-weight-bolder ms-1 mandatory"
                ><span class="red--text"><strong>* </strong></span>CGST</label
              >
              <v-text-field
                v-model.number="gst.cgst"
                hide-details="auto"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  input-icon
                  mt-2
                "
                dense
                flat
                filled
                solo
                height="43"
                persistent-hint
                :disabled="isDisable"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="4" v-show="showLgst"
              ><label class="text-md font-weight-bolder ms-1 mandatory"
                ><span class="red--text"><strong>* </strong></span>SGST</label
              >
              <v-text-field
                v-model.number="gst.sgst"
                hide-details="auto"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  input-icon
                  mt-2
                "
                dense
                flat
                filled
                solo
                height="43"
                persistent-hint
                :disabled="isDisable"
              ></v-text-field></v-col
            ><v-col cols="12" md="4" v-show="showIgst"
              ><label class="text-md font-weight-bolder ms-1 mandatory"
                ><span class="red--text"><strong>* </strong></span>IGST</label
              >
              <v-text-field
                v-model.number="gst.igst"
                hide-details="auto"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  input-icon
                  mt-2
                "
                dense
                flat
                filled
                solo
                height="43"
                persistent-hint
                :disabled="isDisable"
              ></v-text-field
            ></v-col>
            <v-col class="d-flex justify-end mx-5" v-show="showEdit"
              ><v-btn
                @click="enableEdit"
                elevation="0"
                :ripple="false"
                height="43"
                dark
                v-show="isDisable"
                class="
                  font-weight-bold
                  text-capitalize
                  btn-primary
                  bg-success
                  mt-7
                "
                >Edit</v-btn
              >

              <v-btn
                @click="saveGst"
                elevation="0"
                :ripple="false"
                height="43"
                dark
                v-show="!isDisable"
                class="
                  font-weight-bold
                  text-capitalize
                  btn-primary
                  bg-success
                  mt-7
                "
                >Save</v-btn
              >
            </v-col>
          </v-row>
          <v-row class="mb-5 mt-5"> </v-row>
        </div>
      </v-card>
      <v-row class="mt-8">
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <div class="card-header-padding">
                <div class="d-flex align-center">
                  <div>
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      Draft
                    </h5>
                  </div>
                </div>
              </div>
              <v-card-title>
                <v-spacer></v-spacer>
                <v-btn
                  @click="selectMore"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  dark
                  class="
                    font-weight-bold
                    text-capitalize
                    btn-ls btn-primary
                    bg-info
                    py-3
                    px-6
                    mb-5
                    mt-5
                    mr-3
                  "
                  >Select More
                </v-btn>

                <v-btn
                  @click="confirm"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  dark
                  class="
                    font-weight-bold
                    text-capitalize
                    btn-ls btn-primary
                    bg-success
                    py-3
                    px-6
                    mb-5
                    mt-5
                    mr-3
                  "
                  >Save </v-btn
                ><v-btn
                  @click="download"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  dark
                  class="
                    font-weight-bold
                    text-capitalize
                    btn-ls btn-primary
                    bg-success
                    py-3
                    px-6
                    mb-5
                    mt-5
                    mr-3
                  "
                  >Download
                </v-btn>
                <v-btn
                  @click="saveDraft"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  dark
                  class="
                    font-weight-bold
                    text-capitalize
                    btn-ls btn-primary
                    bg-warning
                    py-3
                    px-6
                    mb-5
                    mt-5
                  "
                  >Save as Draft
                </v-btn>
              </v-card-title>
              <v-card-text
                ><v-row>
                  <v-col cols="12" md="2">
                    <label class="text-md font-weight-bolder ms-1 mandatory">
                      Bill Date</label
                    >
                    <v-menu
                      ref="mnu_date"
                      v-model="mnu_date"
                      :close-on-content-click="false"
                      :return-value.sync="mnu_date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          hide-details="auto"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            input-icon
                            mt-5
                          "
                          dense
                          flat
                          filled
                          solo
                          height="46"
                          placeholder="Date"
                          persistent-hint
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="green lighten-1"
                        header-color="primary"
                        v-model="date"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="mnu_date = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.mnu_date.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu> </v-col
                  ><v-col cols="12" md="2">
                    <label class="text-md font-weight-bolder ms-1 mandatory">
                      Mode Of Transport</label
                    >
                    <v-text-field
                      v-model="mode_of_transport"
                      hide-details="auto"
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        input-icon
                        mt-5
                      "
                      dense
                      flat
                      filled
                      solo
                      height="43"
                      placeholder="Enter mode of Transport"
                      persistent-hint
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <label class="text-md font-weight-bolder ms-1 mandatory">
                      Vehicle No</label
                    >
                    <v-text-field
                      hide-details="auto"
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        input-icon
                        mt-5
                      "
                      dense
                      flat
                      filled
                      solo
                      height="43"
                      v-model="vehicle_no"
                      placeholder="Enter Vehicle Number"
                      persistent-hint
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <label class="text-md font-weight-bolder ms-1 mandatory">
                      Driver Contact No</label
                    >
                    <v-text-field
                      hide-details="auto"
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        input-icon
                        mt-5
                      "
                      dense
                      flat
                      filled
                      solo
                      height="43"
                      placeholder="Enter Conatct Number"
                      v-model="driver_contact_no"
                      persistent-hint
                    ></v-text-field> </v-col
                  ><v-col cols="12" md="3">
                    <label class="text-md font-weight-bolder ms-1 mandatory">
                      Remark</label
                    >
                    <v-text-field
                      hide-details="auto"
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        input-icon
                        mt-5
                      "
                      dense
                      flat
                      filled
                      solo
                      height="43"
                      placeholder="Enter Remark"
                      v-model="remarks"
                      persistent-hint
                    ></v-text-field>
                  </v-col> </v-row
                ><v-row>
                  <v-col offset="8">
                    <label
                      class="text-md font-weight-bolder ms-1 mandatory ml-2"
                    >
                      Items Selected :
                      {{ draftData.length + newData.length }}</label
                    ><br />
                    <label class="text-md font-weight-bolder ms-1 mandatory"
                      >Total Quantity : {{ totalQty }}</label
                    >
                  </v-col>
                </v-row>
                <v-row v-if="draftData.length > 0">
                  <v-form ref="frmDraft">
                    <v-data-table
                      v-model="saveList"
                      :headers="headers"
                      :items="draftData"
                      item-key="id"
                      mobile-breakpoint="0"
                      fixed-header
                      show-select
                      class="table"
                      disable-pagination
                      @item-selected="selectOneDraft"
                      @toggle-select-all="allDraftList"
                      :hide-default-footer="true"
                    >
                      <!-- -->
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-toolbar-title>Draft</v-toolbar-title>
                        </v-toolbar>
                      </template>
                      <template
                        v-slot:[`item.ordered_no_of_packages`]="{ item }"
                      >
                        <div>
                          <v-text-field
                            v-model.number="item.ordered_no_of_packages"
                            :disabled="item.no_of_packages == 0"
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                              mt-5
                            "
                            dense
                            flat
                            filled
                            solo
                            :rules="[
                              (v) => v > 0 || 'Min should be above 0',
                              (v) =>
                                v <= item.no_of_packages ||
                                `Max should not be above ${item.no_of_packages}`,
                            ]"
                            :min="0"
                            :max="item.no_of_packages"
                            @change="calcVal(item)"
                          ></v-text-field>
                        </div> </template
                      ><template v-slot:[`item.ordered_quantity`]="{ item }">
                        <div>
                          <v-text-field
                            v-model.number="item.ordered_quantity"
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                              mt-5
                            "
                            dense
                            flat
                            filled
                            solo
                            min="0"
                            :max="item.total_net_kgs"
                            :rules="[
                              (v) => v > 0 || 'Min should be above 0',
                              (v) =>
                                v <= item.total_net_kgs ||
                                `Max should not be above ${item.total_net_kgs}`,
                            ]"
                          ></v-text-field>
                        </div>
                      </template>
                    </v-data-table>
                  </v-form>
                </v-row>
                <!-- Newely Added Data -->
                <v-row v-if="newData.length > 0">
                  <v-form ref="frmNewData">
                    <v-data-table
                      v-model="newList"
                      :headers="headers"
                      :items="newData"
                      item-key="id"
                      mobile-breakpoint="0"
                      fixed-header
                      show-select
                      class="table"
                      disable-pagination
                      :hide-default-footer="true"
                      @item-selected="selectOneNewData"
                      @toggle-select-all="allSelectedNewDataList"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-toolbar-title>New Data</v-toolbar-title>
                        </v-toolbar>
                      </template>
                      <template
                        v-slot:[`item.ordered_no_of_packages`]="{ item }"
                      >
                        <div>
                          <v-text-field
                            v-model.number="item.ordered_no_of_packages"
                            :disabled="item.no_of_packages == 0"
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                              mt-5
                            "
                            dense
                            flat
                            filled
                            solo
                            :rules="[
                              (v) => v > 0 || 'Min should be above 0',
                              (v) =>
                                v <= item.no_of_packages ||
                                `Max should not be above ${item.no_of_packages}`,
                            ]"
                            :min="0"
                            :max="item.no_of_packages"
                            @change="calcVal(item)"
                          ></v-text-field>
                        </div> </template
                      ><template v-slot:[`item.ordered_quantity`]="{ item }">
                        <div>
                          <v-text-field
                            v-model.number="item.ordered_quantity"
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                              mt-5
                            "
                            dense
                            flat
                            filled
                            solo
                            min="0"
                            :max="item.total_net_kgs"
                            :rules="[
                              (v) => v > 0 || 'Min should be above 0',
                              (v) =>
                                v <= item.total_net_kgs ||
                                `Max should not be above ${item.total_net_kgs}`,
                            ]"
                          ></v-text-field>
                        </div>
                      </template> </v-data-table
                  ></v-form>
                </v-row>
              </v-card-text>
              <v-card-text> </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>

      <!-- Show SelectMore  -->
      <v-dialog
        v-model="showSelectMore"
        persistent
        fullscreen
        transition="dialog-bottom-transition"
      >
        <v-card class="card-shadow border-radius-xl">
          <v-card-title>
            <v-row>
              <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                <label class="text-md text-typo font-weight-bolder ms-1"
                  >Grade</label
                >
                <v-autocomplete
                  @change="filterByGradeMark()"
                  :items="grade"
                  item-value="grade_name"
                  item-text="grade_name"
                  v-model="filter.grade"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    select-style
                    mt-3
                  "
                  outlined
                  chips
                  multiple
                  height="46"
                  single-line
                  placeholder="Select Grade"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip
                      v-if="index === 0"
                      label
                      color="bg-default"
                      class="py-1 px-2 my-0"
                    >
                      <span class="text-white text-caption ls-0">{{
                        item.grade_code
                      }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text text-caption">
                      (+{{ filter.grade.length - 1 }}
                      others)
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                <label class="text-md text-typo font-weight-bolder ms-1"
                  >Mark</label
                >
                <v-autocomplete
                  :items="mark"
                  @change="filterByGradeMark()"
                  item-value="mark_name"
                  item-text="mark_name"
                  v-model="filter.mark"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    select-style
                    mt-3
                  "
                  outlined
                  chips
                  multiple
                  height="46"
                  single-line
                  placeholder="Select Mark"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip
                      v-if="index === 0"
                      label
                      color="bg-default"
                      class="py-1 px-2 my-0"
                    >
                      <span class="text-white text-caption ls-0">{{
                        item.mark_code
                      }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text text-caption">
                      (+{{ filter.mark.length - 1 }}
                      others)
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-spacer></v-spacer
            ><label class="text-md font-weight-bolder ms-1 mandatory">
              Items Selected : {{ moreList.length }}
            </label>

            <v-btn
              @click="addMore"
              elevation="0"
              :ripple="false"
              height="43"
              dark
              class="
                font-weight-bold
                text-capitalize
                btn-ls btn-primary
                bg-success
                py-3
                px-6
                mb-5
                mt-5
                mr-5
                ml-5
              "
              >Add
            </v-btn>
            <v-btn
              @click="showSelectMore = false"
              icon
              elevation="0"
              :ripple="false"
              height="28"
              min-width="36"
              width="36"
              color="green"
              >close
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-data-table
              v-model="moreList"
              :headers="selectMoreHeader"
              :items="allList"
              item-key="id"
              mobile-breakpoint="0"
              :search="filter.search"
              fixed-header
              show-select
              class="table"
              disable-pagination
              :hide-default-footer="true"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <!-- <v-toolbar-title>Private Allocation</v-toolbar-title> -->
                  <v-row>
                    <v-col cols="12" md="6" lg="6">
                      <v-text-field
                        hide-details
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                          input-icon
                        "
                        dense
                        flat
                        filled
                        solo
                        height="46"
                        v-model="filter.search"
                        placeholder="Search with : Batch No / Invoice NO / Item Name"
                      >
                        <template slot="prepend-inner">
                          <v-icon color="#adb5bd" size=".875rem"
                            >fas fa-search</v-icon
                          >
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Dialogue Remove Selected Draft Confirm -->
      <v-dialog v-model="dialogueDraftRemove" max-width="500px" persistent>
        <v-card class="card-shadow card-padding border-radius-xl">
          <v-card-title class="pt-0 text-h5 text-typo justify-center">
            <p class="text-center">Do you want to Remove this item?</p>
          </v-card-title>
          <v-card-actions class="pb-0">
            <v-spacer></v-spacer>
            <v-btn
              @click="cancelRemove"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls bg-light py-3 px-6"
              >No</v-btn
            >

            <v-btn
              @click="confirmRemove"
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-bold
                text-capitalize
                btn-ls btn-primary
                bg-success
                py-3
                px-6
              "
              >Yes</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialogue Remove All Draft Confirm -->
      <v-dialog v-model="dialogueAllDraftRemove" max-width="500px" persistent>
        <v-card class="card-shadow card-padding border-radius-xl">
          <v-card-title class="pt-0 text-h5 text-typo justify-center">
            <p class="text-center">Do you want to Remove All item?</p>
          </v-card-title>
          <v-card-actions class="pb-0">
            <v-spacer></v-spacer>
            <v-btn
              @click="cancelDraftAllRemove"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls bg-light py-3 px-6"
              >No</v-btn
            >

            <v-btn
              @click="confirmDraftAllRemove"
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-bold
                text-capitalize
                btn-ls btn-primary
                bg-success
                py-3
                px-6
              "
              >Yes</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialogue Remove Selected New Data Confirm -->
      <v-dialog v-model="dialogueNewDataRemove" max-width="500px" persistent>
        <v-card class="card-shadow card-padding border-radius-xl">
          <v-card-title class="pt-0 text-h5 text-typo justify-center">
            <p class="text-center">Do you want to Remove this item?</p>
          </v-card-title>
          <v-card-actions class="pb-0">
            <v-spacer></v-spacer>
            <v-btn
              @click="cancelNewDataRemove"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls bg-light py-3 px-6"
              >No</v-btn
            >

            <v-btn
              @click="confirmNewDataRemove"
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-bold
                text-capitalize
                btn-ls btn-primary
                bg-success
                py-3
                px-6
              "
              >Yes</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialogue Remove All New Data Confirm -->
      <v-dialog v-model="dialogueAllNewDataRemove" max-width="500px" persistent>
        <v-card class="card-shadow card-padding border-radius-xl">
          <v-card-title class="pt-0 text-h5 text-typo justify-center">
            <p class="text-center">Do you want to Remove All item?</p>
          </v-card-title>
          <v-card-actions class="pb-0">
            <v-spacer></v-spacer>
            <v-btn
              @click="cancelAllNewDataRemove"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls bg-light py-3 px-6"
              >No</v-btn
            >

            <v-btn
              @click="confirmAllNewDataRemove"
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-bold
                text-capitalize
                btn-ls btn-primary
                bg-success
                py-3
                px-6
              "
              >Yes</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialogue Save Confirm -->
      <v-dialog v-model="confirmDialog" max-width="500px" persistent>
        <v-card class="card-shadow card-padding border-radius-xl">
          <v-card-title class="pt-0 text-h5 text-typo justify-center">
            <p class="text-center">Do you want to Save With</p>
            <p class="text-center">{{ message }}?</p>
          </v-card-title>
          <v-card-actions class="pb-0">
            <v-spacer></v-spacer>
            <v-btn
              @click="confirmCancel"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls bg-light py-3 px-6"
              >No</v-btn
            >

            <v-btn
              @click="confirmOk"
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-bold
                text-capitalize
                btn-ls btn-primary
                bg-success
                py-3
                px-6
              "
              >Yes</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { json2excel } from "js2excel";
import api from "../api";
import apiMaster from "../../../master/master-get-api";
export default {
  created() {
    this.init();
  },

  data() {
    return {
      // List of selected filters
      internal_transfer_ref: "",
      filter: {
        grade: [],
        mark: [],
        search: "",
      },
      grade: [],
      mark: [],

      overlay: false,
      // Draft
      draft_id: null,
      draftData: [],
      saveList: [],
      //Newely added
      newData: [],
      newList: [],

      //Remove From draft
      dialogueDraftRemove: false,
      dialogueAllDraftRemove: false,
      draftRemoveObj: null,

      //Remove from new Data
      dialogueNewDataRemove: false,
      dialogueAllNewDataRemove: false,
      newDataRemoveObj: null,

      headers: [
        {
          text: "Garden",
          value: "mark_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 100,
          sortable: true,
          filterable: false,
        },
        {
          text: "Grade",
          value: "grade",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: true,
          filterable: false,
        },
        {
          text: "Invoice NO",
          value: "invoice_number",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 100,
          sortable: false,
          filterable: true,
        },

        {
          text: "Item Name",
          value: "item_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: true,
          filterable: true,
        },
        {
          text: "Available (Bags)",
          value: "no_of_packages",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
          filterable: false,
        },
        {
          text: "Net Kgs ",
          value: "net_kgs",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
          filterable: false,
        },
        {
          text: "Available (Kgs)",
          value: "total_net_kgs",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
          filterable: false,
        },
        {
          text: "purchased rate",
          value: "purchased_rate",
          class: "text-secondary font-weight-bolder opacity-7",
          align: "left",
          width: 75,
          sortable: false,
          filterable: false,
        },

        {
          text: "Order (Packages)",
          value: "ordered_no_of_packages",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",

          width: 100,

          sortable: false,
          filterable: false,
        },
        {
          text: "Order (Kgs)",
          value: "ordered_quantity",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",

          width: 100,

          sortable: false,
          filterable: false,
        },
        // {
        //   text: "Batch No",
        //   value: "batch_no",
        //   class: "text-secondary font-weight-bolder opacity-10",
        //   align: "left",
        //   width: 100,
        //   sortable: true,
        //   filterable: true,
        // },
      ],
      //

      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      mnu_date: false,
      mode_of_transport: "",
      vehicle_no: "",
      driver_contact_no: "",
      remarks: "",
      to_company_warehouse: "",
      from_company_warehouse: "",
      draft_master_id: "",

      // Select More
      allList: [],
      moreList: [],
      items: [],
      showSelectMore: false,
      selectMoreHeader: [
        {
          text: "Garden",
          value: "mark_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 100,
          sortable: true,
          filterable: false,
        },
        {
          text: "Grade",
          value: "grade",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: true,
          filterable: false,
        },
        {
          text: "Invoice NO",
          value: "invoice_number",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
          filterable: true,
        },

        {
          text: "Item Name",
          value: "item_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: true,
          filterable: true,
        },
        {
          text: "Available (Bags)",
          value: "no_of_packages",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
          filterable: false,
        },
        {
          text: "Available (Kgs)",
          value: "total_net_kgs",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
          filterable: false,
        },
        {
          text: "purchased rate",
          value: "purchased_rate",
          class: "text-secondary font-weight-bolder opacity-7",
          align: "left",
          width: 75,
          sortable: false,
          filterable: false,
        },
        {
          text: "Batch No",
          value: "batch_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 100,
          sortable: true,
          filterable: true,
        },
      ],
      //
      gstTypes: [
        {
          type: "GST",
        },
        { type: "IGST" },
      ],
      gstType: "IGST",
      showLgst: false,
      gst: {
        cgst: 0,
        id: 0,

        igst: 0,
        sgst: 0,
      },
      isDisable: true,

      showLgst: false,
      showEdit: true,
      showIgst: true,
      // Save transfer

      confirmDialog: false,
      message: "",
    };
  },
  methods: {
    // GST
    typeChange() {
      console.log(this.gstType);
      if (this.gstType == "GST") {
        this.showLgst = true;
        this.showEdit = true;
        this.showIgst = false;
      }
      if (this.gstType == "IGST") {
        this.showLgst = false;
        this.showEdit = true;
        this.showIgst = true;
      }
    },
    enableEdit() {
      this.isDisable = false;
    },
    async saveGst() {
      try {
        this.overlay = true;
        await api.saveGst(this.gst);
        this.showSuccessAlert("GST Saved");
        this.isDisable = true;
        this.overlay = false;
      } catch (error) {
        this.showErrorAlert(error);
      }
    },

    async init() {
      this.overlay = true;

      this.internal_transfer_ref = await api.getRefNo();
      this.overlay = false;
    },
    // ///
    async selectMore() {
      if (this.allList.length == 0) {
        this.overlay = true;
        this.allList = await api
          .getInventoryForDraftSelectMore({
            company_warehouse_id: this.from_company_warehouse,
            draft_master_id: this.draft_master_id,
          })
          .finally(() => {
            this.overlay = false;
          });
      } else {
      }
      this.items = [...this.allList];
      this.showSelectMore = true;
    },
    addMore() {
      this.overlay = true;
      this.showSelectMore = false;
      this.moreList.forEach((el) => {
        el.draft_child_id = el.draft_child_id;
      });
      this.newData = this.moreList;
      this.newList = this.moreList;
      this.overlay = false;
    },
    confirm() {
      if (this.isDisable == true) {
        this.message = "";
        if (this.gstType == "GST") {
          this.message = ` SGST ${this.gst.sgst} % , CGST ${this.gst.cgst} %`;
        }
        if (this.gstType == "IGST") {
          this.message = ` IGST ${this.gst.igst} % `;
        }
        this.confirmDialog = true;
      } else {
        this.showWarningAlert("Please Save GST First");
      }
    },
    confirmCancel() {
      this.confirmDialog = false;
    },
    async confirmOk() {
      // this.overlay = true;
      this.confirmDialog = false;
      await this.saveTransfer();
      // this.overlay = false;
    },

    validate() {
      let status = false;
      if (this.draftData.length > 0) {
        status = this.$refs.frmDraft.validate();
      }
      if (this.newData.length > 0) {
        status = this.$refs.frmNewData.validate();
      }
      if (this.draftData.length > 0 && this.newData.length > 0) {
        status =
          this.$refs.frmNewData.validate() && this.$refs.frmDraft.validate();
      }
      return status;
    },

    async saveTransfer() {
      if (this.validate()) {
        this.overlay = true;
        let master = {
          sale_date: this.date,
          // vendor: this.vendors[0],
          purchase_type: "INTERNAL TRANSFER",

          // From WareHouse as Vendor
          from_company_ware_house: this.from_company_warehouse,
          // To wareHouse
          company_warehouse: this.to_company_warehouse,
          gstType: this.gstType,
          po_number: "",
        };
        let req = {
          driver_contact_no: this.driver_contact_no,
          mode_of_transport: this.mode_of_transport,
          vehicle_no: this.vehicle_no,
          remarks: this.remarks,
          master: master,
          child: this.saveList.concat(this.newList),
          internal_transfer_ref: this.internal_transfer_ref,
        };
        let r = await api
          .saveData(req)
          .then((res) => {
            this.showSuccessAlert("Data Saved...");
            this.saveList = [];
            this.$router.push({
              name: "Internal Transfer History",
            });
          })
          .finally(() => {
            this.overlay = false;
          });
      }
    },
    async saveDraft() {
      let child = [];
      this.saveList.forEach((el) => {
        let r = {
          ordered_no_of_packages: el.ordered_no_of_packages, //ordered

          ordered_quantity: el.ordered_quantity, //Ordered

          inventory_id: el.id,

          draft_child_id: el.draft_child_id,
        };
        child.push(r);
      });
      this.newList.forEach((el) => {
        let r = {
          ordered_no_of_packages: el.ordered_no_of_packages, //ordered

          ordered_quantity: el.ordered_quantity, //Ordered

          inventory_id: el.id,

          draft_child_id: el.draft_child_id,
        };
        child.push(r);
      });
      let req = {
        from_company_ware_house: this.from_company_warehouse, //From

        company_warehouse: this.to_company_warehouse, //To

        sale_date: this.date,

        driver_contact_no: this.driver_contact_no,

        mode_of_transport: this.mode_of_transport,

        vehicle_no: this.vehicle_no,

        remarks: this.remarks,

        draft_master_id: this.draft_master_id,
        child,
      };
      try {
        // console.log("req", req);
        this.overlay = true;
        await api
          .updateDraft(req)
          .then((res) => {
            this.showSuccessAlert("Draft Saved...");
            this.saveList = [];
            this.$router.push({
              name: "Internal Transfer History",
            });
          })
          .finally(() => {
            this.overlay = false;
          });
      } catch (error) {}
    },

    //  Remove From draft (Single)

    selectOneDraft(obj) {
      this.dialogueDraftRemove = true;
      this.draftRemoveObj = obj.item;
    },
    cancelRemove() {
      this.dialogueDraftRemove = false;
      this.draftRemoveObj = null;
      this.saveList = this.draftData;
    },
    async confirmRemove() {
      this.overlay = true;
      this.dialogueDraftRemove = false;

      let indx = this.draftData.findIndex((el) => el == this.draftRemoveObj);
      let id = this.draftData[indx].draft_child_id;
      await api.removeDraft(id).finally(() => {
        this.overlay = false;
      });
      this.showSuccessAlert("Data Removed from Draft...");

      this.draftData.splice(indx, 1);
      this.draftRemoveObj = null;
    },

    //  Remove From draft (All)

    allDraftList() {
      this.dialogueAllDraftRemove = true;
    },
    cancelDraftAllRemove() {
      this.dialogueAllDraftRemove = false;
      this.saveList = this.draftData;
    },
    async confirmDraftAllRemove() {
      this.overlay = true;
      this.dialogueAllDraftRemove = false;
      await api
        .removeAllDraft(this.draft_master_id)
        .then((res) => {
          this.showSuccessAlert("Data Removed from Draft...");
          this.saveList = [];
          this.draftData = [];
        })
        .catch((err) => {
          this.showErrorAlert(err);
        })
        .finally(() => {
          this.overlay = false;
        });
    },

    // Remove From New Data
    selectOneNewData(obj) {
      this.dialogueNewDataRemove = true;
      this.newDataRemoveObj = obj.item;
    },
    cancelNewDataRemove() {
      this.dialogueNewDataRemove = false;
      this.newList = this.newData;
      this.newDataRemoveObj = null;
    },
    confirmNewDataRemove() {
      this.dialogueNewDataRemove = false;
      let idx = this.newData.findIndex((el) => el == this.newDataRemoveObj);
      this.newData.splice(idx, 1);

      this.newDataRemoveObj = null;
    },
    allSelectedNewDataList() {
      this.dialogueAllNewDataRemove = true;
    },
    cancelAllNewDataRemove() {
      this.dialogueAllNewDataRemove = false;
      this.newList = this.newData;
    },
    confirmAllNewDataRemove() {
      this.dialogueAllNewDataRemove = false;
      this.newList = [];
      this.newData = [];
    },
    //Filter

    filterByGradeMark() {
      this.allList = [...this.items];
      if (this.filter.grade.length > 0) {
        this.allList = this.allList.filter((item) => {
          return this.filter.grade.includes(item.grade);
        });
      }
      if (this.filter.mark.length > 0) {
        this.allList = this.allList.filter((item) => {
          return this.filter.mark.includes(item.mark_name);
        });
      }

      console.log(" this.allList", this.allList);
    },

    //
    calcVal(item) {
      item.ordered_quantity = item.ordered_no_of_packages * item.net_kgs;
      if (item.ordered_quantity > item.total_net_kgs) {
        item.ordered_quantity = item.total_net_kgs;
      }
    },
    // Download

    download() {
      let draftData = this.saveList.concat(this.newList);

      if (draftData.length > 0) {
        let data = draftData.map((item, i) => {
          return {
            "#": i + 1,
            Garden: item.mark_name,
            Grade: item.grade,
            "Invoice Number": item.invoice_number,
            "Item Name": item.item_name,

            "Available(Bags)": item.no_of_packages,
            "Net KGS": item.net_kgs,
            "Available(KGS)": item.total_net_kgs,
            "Purchase Rate": item.purchased_rate,
            "Order (Packages)": item.ordered_no_of_packages,
            "Order(Kgs)": item.ordered_quantity,
            "Batch No": item.batch_no,
          };
        });

        try {
          json2excel({
            data,
            name: "Internal Transfer Draft",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.error("Excel export error:", e);
        }
      }
    },
    // //////
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        showConfirmButton: false,
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },

    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,

        type: "error",

        icon: "error",
        showConfirmButton: true,
      });
    },
    nodDataAlert(message) {
      this.$swal({
        text: message,
        showConfirmButton: false,

        timer: 3000,
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
      });
    },
  },

  async mounted() {
    this.draft_id = this.$route.params.item;
    if (this.draft_id) {
      this.grade = await apiMaster.getGrade();
      this.mark = await apiMaster.getMark();
      this.overlay = true;
      this.draftData = await api.viewDraft(this.draft_id);
      if (this.draftData.length > 0) {
        this.saveList = this.draftData;
        this.mode_of_transport = this.draftData[0].mode_of_transport;
        this.vehicle_no = this.draftData[0].vehicle_no;
        this.driver_contact_no = this.draftData[0].driver_contact_no;
        this.remarks = this.draftData[0].remarks;
        this.date = this.draftData[0].sale_date.split("T")[0];
        this.to_company_warehouse = this.draftData[0].to_company_warehouse;
        this.from_company_warehouse = this.draftData[0].from_company_warehouse;
        this.draft_master_id = this.draftData[0].draft_master_id;
      }
      this.overlay = false;
    } else {
      this.$router.push({
        name: "Internal Transfer History",
      });
    }

    // GST

    let result = await api.getGst();
    this.gst.cgst = result[0].cgst;
    this.gst.sgst = result[0].sgst;
    this.gst.igst = result[0].igst;

    if (this.gst.cgst > 0 || this.gst.sgst > 0) {
      this.gstType = "GST";
      this.showLgst = true;
      this.showIgst = false;
    }
    if (this.gst.igst > 0) {
      this.gstType = "IGST";
      this.showIgst = true;
      this.showLgst = false;
    }
    this.selectedData = [];
    this.selectedList = [];

    this.gst.id = result[0].id;

    // this.currentPage = this.$route.params.currentPage;

    // this.internalTransferDetails = await api.getInternalTransferDetails(
    //   this.internalTransfer.id
    // );
  },
  computed: {
    totalQty() {
      let qty = 0;
      this.saveList.forEach((el) => {
        qty = parseFloat(el.ordered_quantity) + parseFloat(qty);
      });
      this.newList.forEach((el) => {
        qty = parseFloat(el.ordered_quantity) + parseFloat(qty);
      });
      return parseFloat(qty.toFixed(2));
    },
  },
};
</script>

<style></style>
